import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { graphql } from "gatsby";

// Context
import { StoreContext } from "../../../components/context/store";
import { ConnectionLayout } from "../../../components/account/connection-layout";
import { PageColor } from "../../../components/context/page-color";
import { HeaderColor } from "../../../components/context/header-color";
import { CurrentLanguage } from "../../../components/context/current-language";

// Components
import { CustomerLogin } from "../../../components/account/customer-login";
import { CustomerRegistration } from "../../../components/account/customer-registration";
import { CustomerForgotPassword } from "../../../components/account/customer-forgot-password";
import { PrismicRichText } from "@prismicio/react";

// SEO
import { PageSeo } from "../../../components/global/page-seo";

const Page = styled.div`
  padding: 0 50px;

  @media (max-width: 1445px) {
    padding: 0 40px;
  }

  @media (max-width: 999px) {
    padding: 0 30px;
  }

  @media (max-width: 800px) {
    &.page {
      margin: 220px 0 0 0;
    }

    padding: 0 25px;
  }

  & .left-column,
  & .right-column {
    width: 100%;

    @media (max-width: 760px) {
      max-width: 545px;
    }
  }

  @media (max-width: 800px) {
    & .left-column {
      margin: 0 0 100px 0;
    }
  }

  & .customer-fields-container {
    display: grid;
    grid-template-columns: minmax(325px, 545px) minmax(325px, 545px);
    grid-column-gap: 100px;
    margin: 110px 0 0 0;

    @media (max-width: 1445px) {
      grid-column-gap: 80px;
    }

    @media (max-width: 999px) {
      grid-column-gap: 30px;
    }

    @media (max-width: 800px) {
      grid-column-gap: 25px;
    }

    @media (max-width: 760px) {
      display: block;
      grid-template-columns: unset;
      grid-column-gap: unset;
    }

    & h3 {
      margin: 0 0 20px 0;
    }
  }
`;

const LoginForm = ({
  accountText,
  currentLanguage,
  setCurrentLanguage,
  customerAccessToken,
  handleCustomerAccessToken,
  passwordForgot,
  setPasswordForgot,
}) => {
  return (
    <Page className="page">
      <div className="title-container">
        <h1>Mon Compte</h1>

        <div className="text-container">
          <PrismicRichText field={accountText.signup_text.richText} />
        </div>
      </div>

      <div className="customer-fields-container">
        <div className="left-column">
          {passwordForgot ? (
            <>
              <h3>
                {currentLanguage === `en`
                  ? `Forgot password`
                  : `Mot de passe oublié`}
              </h3>

              <CustomerForgotPassword
                passwordForgot={passwordForgot}
                setPasswordForgot={setPasswordForgot}
                currentLanguage={currentLanguage}
              />
            </>
          ) : (
            <>
              <h3>
                {currentLanguage === `en`
                  ? `Existing Customer`
                  : `Se connecter`}
              </h3>

              <CustomerLogin
                currentLanguage={currentLanguage}
                handleCustomerAccessToken={handleCustomerAccessToken}
                passwordForgot={passwordForgot}
                setPasswordForgot={setPasswordForgot}
              />
            </>
          )}
        </div>

        <div className="right-column">
          <h3>{currentLanguage === `en` ? `New Customer` : `S’inscrire`}</h3>
          <CustomerRegistration
            currentLanguage={currentLanguage}
            accountText={accountText}
          />
        </div>
      </div>
    </Page>
  );
};

const Login = ({ data }) => {
  const [currentLanguage, setCurrentLanguage] = useContext(CurrentLanguage);
  const { setValue, customerAccessToken } = useContext(StoreContext);

  const handleCustomerAccessToken = (value) => {
    setValue(value);
  };

  const [pageColor, setPageColor] = useContext(PageColor);
  const [headerColor, setHeaderColor] = useContext(HeaderColor);

  // Forgot Password
  const [passwordForgot, setPasswordForgot] = useState(false);

  useEffect(() => {
    setPageColor(`#fff`);
    setHeaderColor(`#fff`);
  }, []);

  return (
    <>
      <PageSeo
        title={currentLanguage === `en` ? `Login` : `Se connecter`}
        image={null}
        description={null}
        url={null}
      />
      <ConnectionLayout customerAccessToken={customerAccessToken}>
        <LoginForm
          currentLanguage={currentLanguage}
          setCurrentLanguage={setCurrentLanguage}
          customerAccessToken={customerAccessToken}
          handleCustomerAccessToken={handleCustomerAccessToken}
          passwordForgot={passwordForgot}
          setPasswordForgot={setPasswordForgot}
          accountText={data.prismicAccounts.data}
        />
      </ConnectionLayout>
    </>
  );
};

export default Login;

export const query = graphql`
  {
    prismicAccounts(lang: { eq: "fr-fr" }) {
      data {
        signup_text {
          richText
        }
        newsletter_signup_text
        privacy_policy_text {
          richText
        }
        my_account_text {
          richText
        }
      }
    }
  }
`;
